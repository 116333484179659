'use client'

import { ErrorPageProps } from './error'

export default function SiteDownPageContent({ error, reset }: ErrorPageProps) {
  if (error) {
    console.error(error)
  }

  return (
    <section
      data-testid="section"
      aria-current="false"
      className="relative flex w-full flex-1 flex-col items-stretch overflow-x-clip bg-neutral-850 text-neutral-50 first-of-type:top-0 peer-[.fixed]:top-0"
    >
      <div className="relative flex w-full flex-1 justify-center">
        <div className="flex h-full w-full max-w-7xl flex-1 flex-col justify-stretch px-10">
          <div className="mb-14 flex h-[calc(20vh-3.5rem)] items-end pt-[20vh] [section:first-of-type_&]:mt-[20vh]">
            <div className="grid grid-cols-1 gap-y-10 pt-10 xs:grid-cols-[max-content_1fr] xs:gap-y-10 sm:pt-0">
              <div className="col-span-full">
                <div className="relative flex flex-1">
                  <div>
                    <h2
                      className="grid auto-rows-auto grid-cols-1 gap-y-2.5 pt-32 uppercase"
                      aria-label="500 Server Error"
                    >
                      <span className="col-start-1 flex h-6 w-full place-items-end font-display text-2xl font-semibold leading-none tracking-normal sm:h-8 sm:text-3xl sm:leading-none md:h-10 md:text-3.5xl md:leading-none lg:h-12 lg:text-5xl lg:leading-none">
                        Site is Down
                      </span>
                      <span className="col-start-1 flex h-10 w-full place-items-start font-sans text-3.5xl font-semibold tracking-normal sm:h-8 sm:text-4.5xl sm:leading-none md:h-14 md:text-5.5xl md:leading-none lg:h-[4.5rem] lg:text-7xl lg:leading-none">
                        For Maintenance
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full max-w-screen-sm md:mx-auto md:max-w-full md:gap-20">
              <div className="prose max-w-2xl text-current prose-headings:mb-[1em] prose-headings:mt-[1.5em] prose-headings:font-bold prose-h1:text-3xl prose-h2:text-2xl prose-h3:font-display prose-h3:text-3.5xl prose-h3:font-semibold prose-h3:uppercase prose-h3:tracking-wider prose-h3:text-neutral-50 prose-h4:text-lg prose-h5:text-base prose-p:break-inside-avoid-column prose-p:text-xl prose-p:leading-loose prose-strong:font-bold prose-strong:text-current md:max-w-2xl md:prose-p:text-xl md:prose-p:leading-loose">
                <p>We&apos;ve called the sparky to come take a look.</p>
              </div>
            </div>
            <button
              className="group mt-14 flex h-12 grow-0 items-center justify-center self-start rounded-bl-none rounded-br-3xl rounded-tl-3xl rounded-tr-none border border-white bg-neutral-850 px-6 text-center font-body text-sm font-extrabold uppercase tracking-wider text-white drop-shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-current"
              onClick={reset}
            >
              <span className="block shrink-0">Please come back later and try again</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
