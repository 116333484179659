'use client'

import ErrorPageContent, { ErrorPageProps } from './error'
import SiteDownPageContent from './site-down'

export default function GlobalError(props: ErrorPageProps) {
  if (props.error) {
    console.error(props.error)
  }
  return (
    <html>
      <body>{process.env.SITE_DOWN ? <SiteDownPageContent {...props} /> : <ErrorPageContent {...props} />}</body>
    </html>
  )
}
